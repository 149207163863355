import React from 'react';
import SectionHeading2 from '../SectionHeading/SectionHeading2';

const sectionHeadingData = {
  title: 'Location In Map'
}

const LocationInMap = ({ data }) => {
  return (
    
    <><div className="st-height-b120 st-height-lg-b210" />
    <SectionHeading2 data={sectionHeadingData} />
    <div className="st-google-map">
      <iframe title='Google Map' src={data}></iframe>
      <div className="st-height-b200 st-height-lg-b210" /></div></>
  )
}

export default LocationInMap;
