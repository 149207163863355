import React from 'react';
import LocationInMap from '../Components/Map/LocationInMap';
import ServiceSlider from '../Components/Slider/ServiceSlider';
import BeforeAfter2 from '../Components/BeforeAfter/BeforeAfter2';
import TestimonialSlider2 from '../Components/Slider/TestimonialSlider2';
import Price2 from '../Components/Price/Price2';
import About4 from '../Components/About/About4';
import Funfact2 from '../Components/Funfact/Funfact2';
import Hero15 from '../Components/Hero/Hero15';
import SpecialistsSlider3 from '../Components/Slider/SpecialistsSlider3';
import PostWrapper3 from '../Components/Post/PostWrapper3';
import Appointment3 from '../Components/Appointment/Appointment3';

const heroData = {
  subTitle: 'Trusted by 25 years',
  title: 'Provide high quality dental services',
  text: 'Our service and the work efficiency of our doctors are the <br /> reasons for our success.',
  imgSrc: '/images/dental/hero-img.jpg',
  videoSrc: 'https://www.youtube.com/embed/XqZsoesa55w?si=8qsB42ndcfvolwAo&amp;start=2',
};

const aboutData = {
  imgSrc: `images/dental/about2.png`,
  title: `Place of high-quality <span className="st-pink">dental</span> services, which make patients happy.`,
  textLine: [
    {
      text: ` "A leading dental clinic dedicated to providing exceptional, high-quality care that prioritizes patient comfort and well-being. Our expert team is committed to creating a positive and welcoming environment, ensuring every visit leaves our patients happy, healthy, and confident in their smiles."`,
    },
    // {
    //   text: ` Duis autem vel eum iriure dolor in hendrerit in vulputate
    //   velit esse molestie consequat, vel illum dolore eu feugiat
    //   nulla facilisis at vero eros et accumsan et iusto odio
    //   dignissim qui blandit praesent luptatum.`,
    // }
  ],
};

const serviceData = {
  sectionHeadingData: {
    title: `The main feature of our service <br /> is to <span className="st-pink">serve</span> the patient.`,
  },
  services: [
    {
      img: 'images/dental/service1.jpg',
      title: 'Root Canal Treatment',
      text: 'A procedure to save and repair a badly infected or decayed tooth by removing the nerve and pulp, cleaning, and sealing the tooth.',
    },
    {
      img: 'images/dental/service2.jpg',
      title: 'Straighten Teeth',
      text: 'Achieve a perfect smile with orthodontic treatments such as braces or clear aligners that gradually correct teeth alignment.',
    },
    {
      img: 'images/dental/service3.jpg',
      title: 'Dental Implants',
      text: 'A durable, long-term solution for replacing missing teeth, providing a natural look and feel with artificial tooth roots.',
    },
    {
      img: 'images/dental/service1.jpg',
      title: 'Root Canal Treatment',
      text: 'A procedure to save and repair a badly infected or decayed tooth by removing the nerve and pulp, cleaning, and sealing the tooth.',
    },
    {
      img: 'images/dental/service2.jpg',
      title: 'Straighten Teeth',
      text: 'Achieve a perfect smile with orthodontic treatments such as braces or clear aligners that gradually correct teeth alignment.',
    },
    {
      img: 'images/dental/service3.jpg',
      title: 'Dental Implants',
      text: 'A durable, long-term solution for replacing missing teeth, providing a natural look and feel with artificial tooth roots.',
    },
  ],
};

const beforeAfterData = {
  bgImg: '/images/before-after-bg.jpg',
  beforeImg: '/images/dental/after.jpg',
  afterImg: 'images/dental/before.jpg',
  sectionHeadingData: {
    title: `Some samples of our work <br> <span class="st-pink">before</span>
  and <span class="st-pink">after</span>`,
  },
};

const priceData = {
  sectionHeadingData: {
    title: `What is the <span className="st-pink">cost</span> your <br />
    dental treatment`,
  },
  pricePlan: [
    {
      title: 'Plan A is for under 15 year olds',
      img: 'images/dental/plana.jpg',
      featureList: [
        {
          title: 'New patient consultation',
          price: '100',
        },
        {
          title: 'Regular checkups',
          price: '250',
        },
        {
          title: 'X-rays',
          price: '100',
        },
        {
          title: 'Root canal',
          price: '495',
        },
        {
          title: 'Straighten teeth',
          price: '350',
        },
        {
          title: 'Porcelain bridge',
          price: '650',
        },
        {
          title: 'Simple extraction',
          price: '180',
        },
      ],
    },
    {
      title: 'Plan B is for above 16 year olds',
      img: 'images/dental/plana2.jpg',
      featureList: [
        {
          title: 'New patient consultation',
          price: '120',
        },
        {
          title: 'Regular checkups',
          price: '270',
        },
        {
          title: 'X-rays',
          price: '120',
        },
        {
          title: 'Root canal',
          price: '520',
        },
        {
          title: 'Straighten teeth',
          price: '370',
        },
        {
          title: 'Porcelain bridge',
          price: '670',
        },
        {
          title: 'Simple extraction',
          price: '200',
        },
      ],
    },
    {
      title: 'Plan C is for above 40 year olds',
      img: 'images/dental/plana3.jpg',
      featureList: [
        {
          title: 'New patient consultation',
          price: '150',
        },
        {
          title: 'Regular checkups',
          price: '300',
        },
        {
          title: 'X-rays',
          price: '150',
        },
        {
          title: 'Root canal',
          price: '550',
        },
        {
          title: 'Straighten teeth',
          price: '400',
        },
        {
          title: 'Porcelain bridge',
          price: '700',
        },
        {
          title: 'Simple extraction',
          price: '220',
        },
      ],
    },
  ],
};

const appointmentData = {
  title: `Make <span className="st-pink">appointment</span> We always welcome new patients`,
  img: `/images/dental/appointment2.png`,
  phone: `+62 818 236 161`,
};

const testimonialData = {
  sectionHeadingData: {
    title: ` All that our clients have  <span class="st-pink">said</span><br/>
    about our work`,
  },
  testimonials: [
    {
      img: 'images/avatar2.png',
      name: 'Ralph Jones',
      designation: 'Executive',
      subTitle:
        "The dental care I received was exceptional. The team made me feel comfortable throughout the process, and the results exceeded my expectations.",
    },
    {
      img: 'images/avatar3.png',
      name: 'Francis Jara',
      designation: 'Biographer',
      subTitle:
        "I was nervous about my procedure, but the staff's professionalism and expertise put me at ease. I’m thrilled with my new smile!",
    },
    {
      img: 'images/avatar4.png',
      name: 'David Baer',
      designation: 'UX Designer',
      subTitle:
        "From start to finish, the experience was fantastic. The clinic’s modern approach and friendly staff made a huge difference in my dental care.",
    },
    {
      img: 'images/avatar2.png',
      name: 'Ralph Jones',
      designation: 'Executive',
      subTitle:
        "The dental care I received was exceptional. The team made me feel comfortable throughout the process, and the results exceeded my expectations.",
    },
    {
      img: 'images/avatar3.png',
      name: 'Francis Jara',
      designation: 'Biographer',
      subTitle:
        "I was nervous about my procedure, but the staff's professionalism and expertise put me at ease. I’m thrilled with my new smile!",
    },
    {
      img: 'images/avatar4.png',
      name: 'David Baer',
      designation: 'UX Designer',
      subTitle:
        "From start to finish, the experience was fantastic. The clinic’s modern approach and friendly staff made a huge difference in my dental care.",
    },
  ],
};

const specialistData = {
  sectionHeadingData: {
    title: ` Professional staff at our <br/><span class="st-pink"> dental </span> hospital`,
  },
  doctors: [
    {
      img: 'images/member1.jpg',
      name: 'Dr. Philip Bailey',
      designation: 'Urology',
      authorLink: 'doctor-profile3',
    },
    {
      img: 'images/member2.jpg',
      name: 'Dr. Vera Hasson',
      designation: 'Cardiology',
      authorLink: 'doctor-profile3',
    },
    {
      img: 'images/member3.jpg',
      name: 'Dr. Matthew Hill',
      designation: 'Neurosurgery',
      authorLink: 'doctor-profile3',
    },
    {
      img: 'images/member4.jpg',
      name: 'Dr. Jeanette Hoff',
      designation: 'Surgery',
      authorLink: 'doctor-profile3',
    },
    {
      img: 'images/member1.jpg',
      name: 'Dr. Philip Bailey',
      designation: 'Urology',
      authorLink: 'doctor-profile3',
    },
    {
      img: 'images/member2.jpg',
      name: 'Dr. Vera Hasson',
      designation: 'Cardiology',
      authorLink: 'doctor-profile3',
    },
    {
      img: 'images/member3.jpg',
      name: 'Dr. Matthew Hill',
      designation: 'Neurosurgery',
      authorLink: 'doctor-profile3',
    },
    {
      img: 'images/member4.jpg',
      name: 'Dr. Jeanette Hoff',
      designation: 'Surgery',
      authorLink: 'doctor-profile3',
    },
  ],
};

const postData = {
  sectionHeadingData: {
    title: `Read the updated <span class="st-pink"> news </span><br/>about
    dental treatment`,
  },
  posts: [
    {
      img: 'images/dental/blog1.jpg',
      title: 'Handling Dental Emergencies: What You Need to Know',
      date: 'Aug 23, 2020',
      author: 'Albert Brian',
      authorLink: '',
      subTitle:
        "Learn how to handle common dental emergencies, from broken teeth to severe toothaches, and when to seek professional care.",
      postLink: '/post/post_details_right_sitebar_One',
    },
    {
      img: 'images/dental/blog2.jpg',
      title: 'Personalized Dental Care: Why It Matters',
      date: 'Aug 22, 2020',
      author: 'William Juarez',
      authorLink: '',
      subTitle:
        "Explore the benefits of individualized dental treatment plans and how they cater to your unique needs for optimal oral health.",
      postLink: '/post/post_details_right_sitebar_One',
    },
    {
      img: 'images/dental/blog3.jpg',
      title: 'Preparing Your Child for Their First Dental Visit',
      date: 'Aug 21, 2020',
      author: 'Jamse Lewis',
      authorLink: '',
      subTitle:
        "A guide for parents on how to make your child's first dental visit a positive experience, setting the foundation for a lifetime of healthy smiles.",
      postLink: '/post/post_details_right_sitebar_One',
    },
    {
      img: 'images/blog3.jpg',
      title: 'Preparing Your Child for Their First Dental Visit',
      date: 'Aug 21, 2020',
      author: 'Jamse Lewis',
      authorLink: '',
      subTitle:
        "A guide for parents on how to make your child's first dental visit a positive experience, setting the foundation for a lifetime of healthy smiles.",
      postLink: '/post/post_details_right_sitebar_One',
    },
  ],
};

const mapLocationURL =
  'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15829.493147630583!2d112.7316510709097!3d-7.311894697901185!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2dd7fb7156d74403%3A0x7c40b2f284032eb2!2sJl.%20Ketintang%20Baru%20IV%20No.9%2C%20Ketintang%2C%20Kec.%20Gayungan%2C%20Surabaya%2C%20Jawa%20Timur%2060231!5e0!3m2!1sen!2sid!4v1725342944104!5m2!1sen!2sid';

const Home15 = () => {
  return (
    <div>
      <div className="st-height-b125 st-height-lg-b80" id="home"></div>
      <Hero15 data={heroData} />
      <About4 data={aboutData} />
      <Funfact2 />
      <section id="services">
        <ServiceSlider data={serviceData} varient="st-type1" />
      </section>
      <BeforeAfter2 data={beforeAfterData} />
      <Price2 data={priceData} varient="st-color2" />
      <section id="testimonial">
        <TestimonialSlider2 data={testimonialData} varient="st-type1 st-color1" />
      </section>
      <SpecialistsSlider3 data={specialistData} />
      <PostWrapper3 data={postData} varient="st-type2" />
      <section id="location">
        <LocationInMap data={mapLocationURL} />
      </section>
      <Appointment3 data={appointmentData} />
    </div >
  );
};

export default Home15;
